import { createApp } from "vue";
import App from "./App.vue";
import "./assets/tailwind.css";

declare global {
  interface Date {
    addHours(h: number): Date;
  }
}

Date.prototype.addHours = function (h) {
  this.setTime(this.getTime() + h * 60 * 60 * 1000);
  return this;
};

createApp(App).mount("#app");
